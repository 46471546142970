html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	background-color: #FFFAEF;
	font-family: "Comfortaa", sans-serif;
	font-optical-sizing: auto;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

* {
	box-sizing: border-box;
	padding: 0;
	scroll-margin-top: 150px;
	scroll-padding-top: 150px;
}

section, footer {
	scroll-margin-top: 120px;
}

a {
	text-decoration: none;
}

.image-wrapper {
	width: 100%;
	height: 100%;
	min-height: 400px;
	position: relative;
	padding: 15px 0;
}

.image {
	height: 100%;
	max-height: 450px;
	display: block;
	margin: 0 auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	box-shadow:
			-10px 0 15px rgba(0, 0, 0, 0.3),
			10px 0 15px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
		/* Opcjonalne: Zaokrąglenie rogów */
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
	max-height: calc(70vh - 80px) !important;
}

.image-gallery-icon:hover {
	color: #4b72f3 !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
	border-color: #00cb7a !important;
}

.image-gallery-thumbnails {
	margin-top: 50px;
}

.splide__arrows svg {
	display: none;
}

.splide__arrow {
	width: 40px !important;
	height: 40px !important;
	background: url(assets//icon/strzałka.svg) white !important;
	background-color: transparent;
	background-size: contain !important;
	background-position: center center !important;
	background-repeat: no-repeat;
	opacity: 1 !important;
}

.splide__arrow--prev {
	transform: translateY(-50%) rotate(180deg) !important;
}

.grid-1-a {
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
	text-align: justify;
	gap: 4vw;
	padding-bottom: 60px;
}

.grid-a-1 {
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: center;
	text-align: justify;
	gap: 4vw;
	padding-bottom: 60px;
}

.grid-a-1 img, .grid-1-a img {
	max-width: 300px;
}

.info {
	display: block;
	padding: 20px;
	background: #e1e1e1;
}

p {
	font-size: 18px;
	line-height: 1.4;
}

@media(max-width: 1000px) {
	.grid-1-a {
		display: flex;
		flex-direction: column-reverse;
		padding: 0 20px 50px;
	}

	.grid-a-1 {
		display: flex;
		flex-direction: column;
		padding: 0 20px 100px;
	}
}